<template>
  <div v-if="isShow" class="exchange-dialog">
    <div class="wrap">
      <div class="ls-icons icon-close" @click="onClose"></div>

      <div class="content-box">
        <div class="ls-icons icon-hint-large"></div>
        <div class="title">提示</div>
        <div class="hint-text">
          付款前请勿关闭本弹窗。
          <br />
          <br />
          如遇到问题请点击下方联系平台根据页面提示完成上报。
        </div>
      </div>

      <div class="btns">
        <div class="ls-btns-block" @click="accomplis">已完成付款</div>
        <div class="ls-btns-block spin" @click="service">联系平台</div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 充值回调弹窗
 */

export default {
  name: 'Prompt',
  data() {
    return {
      isShow: false
    }
  },
  methods: {
    // 显示
    onShow() {
      this.isShow = true
    },
    // 关闭
    onClose() {
      this.isShow = false
      this.$emit('onRefresh')
    },
    // 完成付款
    accomplis() {
      this.isShow = false
      this.$emit('onRefresh')
    },
    // 联系平台
    service() {
      this.$router.push('/report-problem')
    }
  }
}
</script>

<style lang="scss" scoped>
.exchange-dialog {
  z-index: 999;

  @extend %com-dialog-fixed;

  .wrap {
    @extend %v-h-center;
    width: 514px;
    padding: 64px;
    background: #ffffff;
    text-align: center;

    .btns {
      display: flex;
      width: 258px;
      align-items: center;
      justify-content: space-between;
      margin: auto;
    }

    .content-box {
      margin-bottom: 32px;

      .title {
        margin-top: 15px;
        font-size: 14px;
        line-height: 30px;
        text-align: center;
        color: #3d3d3d;
        font-weight: bold;
      }

      .hint-text {
        font-size: 14px;
        text-align: center;
        color: #3d3d3d;
      }
    }
  }

  .icon-close {
    @extend %com-dialog-close;
  }
}
</style>
