<template>
  <div v-if="isShow" class="confirm-dialog">
    <div class="wrap">
      <div v-if="title" class="title">{{ title }}</div>
      <div v-if="closeIcon" class="ls-icons icon-close" @click="onClose"></div>
      <div v-if="content" class="content">{{ content }}</div>
      <slot></slot>
      <div class="btns">
        <div class="ls-btns-block" @click="onConfirm">确认</div>
        <div class="ls-btns-block spin" @click="onClose">取消</div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 确认提示弹窗
 */
export default {
  name: 'Confirm',
  props: {
    title: {
      type: String,
      default: null
    },
    content: {
      type: String,
      default: ''
    },
    closeIcon: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isShow: false
    }
  },
  methods: {
    // 显示
    onShow() {
      this.isShow = true
    },
    // 关闭
    onClose() {
      this.isShow = false
    },
    // 确定
    onConfirm() {
      this.$emit('onConfirm')
    }
  }
}
</script>

<style lang="scss" scoped>
.confirm-dialog {
  z-index: 999;

  @extend %com-dialog-fixed;

  .wrap {
    @extend %v-h-center;
    min-width: 514px;
    max-width: 707px;
    padding: 32px 40px 46px 40px;
    background: #ffffff;
    text-align: center;

    .title {
      text-align: center;
      line-height: 25px;
      height: 25px;
      font-weight: bold;
      font-size: 18px;
      color: #3d3d3d;
      margin-bottom: 6px;
    }

    .content {
      margin: 25px 40px 0 40px;
      font-size: 14px;
      line-height: 30px;
      text-align: center;
      color: #3d3d3d;
      word-break: break-all;
    }

    .btns {
      display: flex;
      width: 258px;
      align-items: center;
      justify-content: space-between;
      margin: 45px auto auto auto;
    }
  }

  .icon-close {
    @extend %com-dialog-close;
  }
}
</style>
