<template>
  <section class="ls-container recharge">
    <BreadCrump :breadcrumbList="breadcrumbList" />

    <div class="recharge-wrap main-section">
      <div class="ls-title">充值</div>

      <div class="content-box flex justifyCenter">
        <div class="form-box">
          <div class="flex alignCenter form-item">
            <div class="label">可用燃料：</div>
            <div class="num">{{ usableTimes }}</div>
          </div>

          <div class="flex alignCenter form-item">
            <div class="label">支付方式：</div>
            <el-radio-group v-model="radio">
              <el-radio :label="1">在线支付</el-radio>
              <el-radio disabled>线下汇款</el-radio>
            </el-radio-group>
          </div>

          <div class="flex form-item">
            <div class="label">支付金额：</div>
            <div class="pay-box">
              <div class="flex alignCenter input-box">
                <el-input class="w-300" v-model.number="money" placeholder="请输入需要充值的金额" @input="handleEdit"></el-input>
                <span>元</span>
              </div>

              <div class="waring-box">
                <i class="el-icon-warning"></i>
                请注意，平台最小充值金额为500元
              </div>

              <div class="moneys-box">
                <div class="btn" v-for="item in nums" :key="item" @click="changeMoeny(item)">{{ item }}元</div>
              </div>
            </div>
          </div>

          <div class="flex alignCenter form-item">
            <div class="label">即可获得：</div>
            <div class="num">
              {{ fuelFor }}
              <span>燃料</span>
            </div>
          </div>

          <div class="ls-btns-block" @click="onComfrim">确认充值</div>
        </div>

        <div class="hint-box">
          <p>温馨提示</p>
          <span>
            1. 充值的金额将会被兑换成对应比例的“燃料”，目前不支持对剩余燃料进行提现操作。
            <br />
            <br />
            2. 已完成充值的金额目前暂不支持退款。
            <br />
            <br />
            3.燃料不支持进行转账或者转赠操作。
            <br />
            <br />
            4. 燃料将会在您使用平台提供的付费服务时，根据公示的燃料消耗从您的账户中进行分配和划转。
          </span>
        </div>
      </div>
    </div>

    <!-- 二次确认弹窗 -->
    <Confirm ref="confirmRef" :title="`你确认支付`" @onConfirm="onConfirmRecharge">
      <div class="confirm-box">
        {{ moneyVal }}
        <span>元</span>
      </div>
    </Confirm>

    <!-- 支付回调弹窗 -->
    <Prompt ref="promptRef" @onRefresh="getGasInfo"></Prompt>

    <!-- 未认证提示窗口 -->
    <HintDialog :item="hintItem" />
  </section>
</template>

<script>
/**
 * 充值
 */
import BreadCrump from '@/components/common/BreadCrump'
import Confirm from '@/components/common/Confirm'
import Prompt from '@/components/wallet/Prompt'
import HintDialog from '@/components/common/HintDialog'
import { gasRate, recharge } from '@/api/interface/order'
import { gasInfo } from '@/api/interface/gas'
import { accMul, ADD_THOUSANDS, formatNumber } from '@/utils/common'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Recharge',
  components: {
    BreadCrump,
    Confirm,
    Prompt,
    HintDialog
  },
  data() {
    return {
      breadcrumbList: [
        { menuName: '我的钱包', routeName: 'FuelManage' },
        { menuName: '充值', curCss: 'cur' }
      ],
      radio: 1,
      nums: [500, 1000, 2000, 5000, 10000, 20000],
      money: undefined,
      loading: false,
      gasRate: 0,
      hintItem: { isShow: false }
    }
  },
  computed: {
    ...mapGetters(['gasInfo', 'userInfo']),
    usableTimes() {
      return formatNumber(
        parseInt(this.gasInfo.usableTimes || 0) - 
          parseInt(this.gasInfo.frozenTimes || 0)
      );
    },
    fuelFor() {
      const fuelFor = accMul(Number(this.money || 0), Number(this.gasRate || 0))
      const val = ADD_THOUSANDS(fuelFor, true)
      return val === '--' ? 0 : val
    },
    moneyVal() {
      const val = ADD_THOUSANDS(Number(this.money || 0), true)
      return val === '--' ? 0 : val
    },
    // 企业认证
    enterpriseAuthStatus() {
      return this.userInfo.shop && this.userInfo.shop.auditStatus
    }
  },
  methods: {
    ...mapMutations(['setGasInfo']),
    // 提示窗口
    authHint() {
      Object.assign(this.hintItem, {
        isShow: true,
        type: 'error',
        iconCss: 'icon-error-large',
        title: '您的账号暂未完成企业认证',
        content: '完成实名认证和企业认证即可解锁大量工作台的服务和功能~',
        btnTextL: '前往安全中心',
        btnFuncL: () => {
          this.$router.push({ name: 'Account' })
        },
        btnTextR: '返回工作台首页',
        btnFuncR: () => {
          this.$router.push({ name: 'Index' })
        }
      })
    },
    // 在 Input 值改变时触发
    handleEdit(e) {
      let value = e.replace(/^(0+)|[^\d]+/g, '') // 以0开头或者输入非数字，会被替换成空
      value = value.replace(/(\d{15})\d*/, '$1') // 最多保留15位整数
      this.money = value
    },
    // 充值金额填充
    changeMoeny(num) {
      this.money = num
    },
    // 确认充值弹窗
    onComfrim() {
      if (this.enterpriseAuthStatus == 2) {
        if (!this.money || Number(this.money) < 500) {
          this.$message.info('请注意，平台最小充值金额为500元！')
          return
        }
        this.$refs['confirmRef'].onShow()
      } else {
        this.authHint()
      }
    },
    // 确认充值
    onConfirmRecharge() {
      this.onRecharge(Number(this.money))
    },
    // 充值
    onRecharge(amount) {
      if (this.loading) return
      this.loading = true
      recharge({ amount })
        .then(res => {
          this.$refs['confirmRef'].onClose()
          this.$refs['promptRef'].onShow()
          if (res) {
            window.open(res)
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    // 获取汇率
    getGasRate() {
      gasRate().then(res => {
        this.gasRate = res
      })
    },
    // 获取燃料
    getGasInfo() {
      gasInfo().then(data => {
        this.setGasInfo(data)
      })
    }
  },
  created() {
    this.getGasInfo()
    this.getGasRate()
  }
}
</script>

<style lang="scss" scoped>
.recharge {
  .recharge-wrap {
    width: 100%;
    margin-top: 16px;
    padding-bottom: 30px;
    background-color: #fff;
    min-height: calc(100vh - 170px);

    .w-300 {
      width: 300px;
    }

    .ls-title {
      padding: 25px 34px;
    }

    .content-box {
      .form-box {
        &:deep(.el-radio__input.is-checked .el-radio__inner) {
          border-color: #000000;
          background: #ffffff;
          &::after {
            width: 7px;
            height: 7px;
            background: #1D8DB9;
          }
        }

        &:deep(.el-radio__input.is-checked + .el-radio__label) {
          color: #3d3d3d;
        }

        &:deep(.el-radio__inner) {
          width: 18px;
          height: 18px;
        }

        margin-right: 45px;

        .form-item {
          margin-top: 45px;
          .label {
            width: 90px;
            font-size: 14px;
            color: #3d3d3d;
            font-weight: 600;
          }

          .num {
            font-size: 36px;
            color: #0846ac;
            font-weight: bold;

            span {
              font-size: 14px;
              color: #3d3d3d;
              font-weight: 400;
            }
          }

          .pay-box {
            width: 500px;

            .input-box {
              &:deep(.el-input-number) {
                width: 290px;
              }

              &:deep(.el-input__inner) {
                border-radius: 0;
                height: 49px;
                border: 1px solid #dddddd;
                text-align: left;
              }

              span {
                margin-left: 10px;
                font-size: 14px;
                color: #3d3d3d;
              }
            }

            .waring-box {
              margin-top: 10px;
              font-size: 12px;
              color: #8a8a8a;

              i {
                color: #f06060;
                margin-right: 8px;
              }
            }

            .moneys-box {
              margin-top: 10px;
              display: flex;
              align-items: center;
              flex-wrap: wrap;

              .btn {
                cursor: pointer;
                width: 101px;
                height: 38px;
                background: #ffffff;
                box-sizing: border-box;
                border: 1px solid #0846ac;
                font-size: 14px;
                color: #0846ac;
                text-align: center;
                line-height: 38px;
                margin-right: 22px;
                margin-top: 17px;

                &:hover {
                  background: #0846ac;
                  color: #ffffff;
                }
              }
            }
          }
        }

        .ls-btns-block {
          margin-left: 90px;
          margin-top: 45px;
        }
      }

      .hint-box {
        margin-top: 14px;
        width: 310px;
        height: 448px;
        background: #f4f4f4;
        box-sizing: border-box;
        border: 1px solid #e0e3ea;
        padding: 28px 26px;
        font-size: 14px;

        p {
          color: #3d3d3d;
        }

        span {
          color: #8a8a8a;
        }
      }
    }
  }

  .confirm-box {
    margin-top: 35px;
    font-size: 36px;
    color: #0846ac;

    span {
      font-size: 14px;
      color: #3d3d3d;
    }
  }
}
</style>
